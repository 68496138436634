import type { Directive } from 'vue'

/**
 * Directive that when its value is `true` prevents users from scrolling the
 * topmost element of the DOM. Otherwise, it allows users to scroll normally.
 *
 * This is especially useful for modals and full-screen popups that should
 * prevent users from scrolling content that's behind them.
 */
export const vRemoveBodyScroll = {
  beforeMount: function (el: HTMLElement, binding: any) {
    if (binding && binding.value === true)
      document.documentElement.style.overflow = 'hidden'
  },
  updated: function (el: HTMLElement, binding: any) {
    if (binding && binding.value === true)
      document.documentElement.style.overflow = 'hidden'
    else document.documentElement.style.overflow = 'auto'
  },
  unmounted: function () {
    document.documentElement.style.overflow = 'auto'
  },
} satisfies Directive
